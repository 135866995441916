import styled, {css, keyframes} from 'styled-components'

const grow = keyframes`
  from {
    width: 20%;
  }
  to {
    width: 95%
  }
`;

const CategoriesWrapper = styled.div`
  background: ${props => props.theme.colors.main};
  border-radius: ${props => props.theme.borderRadius.md};
  padding: ${props => props.theme.spacings.sm};
  transition: ${props => props.theme.transitions.md};
  width: 94%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: ${props => props.theme.transitions.md};
  animation: ${grow} 400ms linear;
  
  ${props =>
    props.isFixed &&
    css`
      top: 61px;
      transform: translate(-50%, 0);
      padding: ${props => `18px ${props.theme.spacings.sm}`};
      background: ${props => props.theme.colors.mainLight};
      border-radius: 0;
      width: 100%;
      box-sizing: border-box;
  `};
`;

export default CategoriesWrapper
