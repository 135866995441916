const ar = {
	howMuchDoYouCare: 'ما هو مدى اهتمامك بالمصالح التي تشتري منها عادة؟',
	dontCare: 'لا يهمني',
	hardlyCare: 'بالكاد يهمني',
	somewhatCare: 'يهمني الى حد ما',
	care: 'يهمني',
	careALot: 'يهمني جداً',
	whatIsThePercentageOfBUs: 'حسب رأيك، كم هي نسبة المصالح المحلية التي تعاني بسبب كورونا؟',
	nextBtn: 'التالي',
	explanationText: 'يمكنك مساعدة المصلحة المفضلة لديك من خلال اخبار اصدقائك عنها، وأيضا بالالتزام بالشراء منها على الأقل مرة واحدة خلال هذا الشهر',
	startBtn: 'هيا نبدأ',
	questionCafe: 'ما هو مقهاك المفضل؟',
	questionPub: '',
	questionRestaurant: 'المطعم الذي تذهب اليه عادة، هو؟',
	questionHairdresser: 'من يقص لك شعرك؟',
	questionLocalShop: 'ما هو دكانك المحلي المفضل؟',
	questionOther: 'ما هي الخدمات الأخرى التي تفتقدها؟',
	skipCat: 'تجاوز الفئة',
	addBtn: 'أضف الى المفضلة',
	addedBtn: 'تمت الإضافة',
	whatIsYourEmail: 'هل تود المساعدة أكثر؟',
	description: 'سوف نراسلك في حال كانت هناك طرق أخرى يمكنك من خلالها مساعدة المصالح المفضلة لديك.',
	sendBtn: 'هذا هو بريدي الالكتروني',
	thanksContinue: 'لا شكرا، تابع',
	thankYou: 'هذه هي المصالح المحلية المفضلة لدي وسأشتري منها هذا الشهر.',
	linksDescription: '',
	shareBtn: 'التزم وشارك!',
	validationRequired: 'مطلوب',
	validationInvalidEmail: 'عنوان البريد الإلكتروني غير صالح',
	validationEmptyBusinesses: 'يرجى إضافة واحدة على الأقل من أعمالك المفضلة',
	cancelBtn: 'إلغاء',
	copyLink: 'Copy link',
	copied: 'Copied',
	sharePost: 'هذه هي المصالح المحلية المفضلة لدي، ولمساعدتها سأقوم بالشراء منها على الأقل مرة واحدة هذا الشهر. \n',
	sharePostQuestion: 'ما هي مصالحك المفضلة؟ \n',
	sharePostHashtag: '#locals',
}

export default ar
