import styled, {css, keyframes} from 'styled-components'

const grow = keyframes`
  from {
      width: 0;
      height: 0;
      font-size: 6px;
    }
    to {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  color: red;
  margin: ${props => `0 ${props.theme.spacings.sm}`};
  transition: ${props => props.theme.transitions.md};
  animation: ${grow} 400ms linear;
  
  ${props =>
    props.isInactive &&
    css`
       background: ${props => props.theme.colors.main};
       border: ${props => `2px solid ${props.theme.colors.main}`};
  `};
  
  ${props =>
    props.isActive &&
    css`
      background: #fff;
      border: ${props => `2px solid ${props.theme.colors.main}`};
  `};
  
`

export default IconWrapper
