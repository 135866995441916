import React, { Component } from 'react'
import Slider from 'rc-slider/lib/Slider'
import 'rc-slider/assets/index.css'
import { buHappinessLabels } from '../config/buHappinessLabels'

class RangeSlider extends Component {

  constructor (props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  handleChangeStart() {
    //console.log('Change event started')
  };

  handleChange(value) {
    this.setState({
      value
    })
  };

  handleChangeComplete() {
    this.props.onChange(this.state.value)
    //console.log('Change event completed')
  };

  formatValue(value) {
    const { type} = this.props
    if (type === 'number') return value + '%'

    return buHappinessLabels[value]
  }

  render () {
    const { value } = this.state
    const { min, max, step, isRtl } = this.props

    return <div>
      <Slider
        className='c-rc-slider'
        min={min}
        max={max}
        step={step}
        defaultValue={value}
        onBeforeChange={() => this.handleChangeStart()}
        onChange={(value) => this.handleChange(value)}
        onAfterChange={() => this.handleChangeComplete()}
        reverse={isRtl}
      />
      <div className='c-rc-slider c-value'>{this.formatValue(value)}</div>
    </div>
  }
}

export default RangeSlider
