import React from 'react'
import he from './he'
import ar from './ar'
import us from './us'

export const dictionaryList = {
	he,
	ar,
	us
}

export const languageOptions = [
	{
	  id: 'he',
		text: 'Hebrew',
		abbr: 'HE',
		flag: <span role='img' aria-label='Flag of Israel'>🇮🇱</span>,
		icon: <img src='imgs/icon-lang-he.png' width='18' alt='Hebrew' />,
		logo: 'imgs/logo-he.svg',
		countryCode: 'il'
	},
	{
		id: 'ar',
		text: 'Arabic',
		abbr: 'AR',
		flag: <span role='img' aria-label='Flag of Israel'>🇮🇱</span>,
		icon: <img src='imgs/icon-lang-ar.png' width='18' alt='Arabic' />,
		logo: 'imgs/logo-ar.svg',
		countryCode: 'il'
	},
	{
	  id: 'us',
		text: 'English',
		abbr: 'US',
		flag: <span role='img' aria-label='Flag of United States'>🇺🇸</span>,
		icon: <img src='imgs/icon-lang-us.png' width='18' alt='English' />,
		logo: 'imgs/logo-us.svg',
		countryCode: ''
	}
]
