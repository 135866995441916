import React from 'react'
import Select from 'react-select'

class CustomSelect extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedOption: props.value,
    }
  }

  handleChange(selectedOption) {
    this.setState(
      { selectedOption },
      () => this.props.onChange(selectedOption)
    )
  }
  render() {
    const { selectedOption } = this.state

    return (
      <Select
        className='c-custom-select'
        classNamePrefix='c-custom-select'
        value={selectedOption}
        onChange={value => this.handleChange(value)}
        options={this.props.options}
        isSearchable={false}
      />
    )
  }
}

export default CustomSelect
