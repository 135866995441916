import { createGlobalStyle } from 'styled-components/macro'

export default createGlobalStyle`
  body {
    font-family: ${props => `${props.lang === 'ar' ? 'Harmattan' : 'Rubik'}, sans-serif`};
    direction: ${props => props.isRtl ? 'rtl' : 'ltr'};
    background: ${props => props.theme.colors.gray};
  }
  
  p, button, input, .u-direction {
    direction: ${props => props.isRtl ? 'rtl' : 'ltr'};
  }
  
  button:focus {
    outline: none;
  }
  
  .u-direction--ltr {
    direction: ltr
  }
  
  .u-text-align {
    text-align: ${props => props.isRtl ? 'right' : 'left'};
  }
  
  .u-margin-bottom-sm {
    margin-bottom: ${props => props.theme.spacings.sm};
  }
  
  .u-padding-horizontal-md {
    padding-left: ${props => props.theme.spacings.md};
    padding-right: ${props => props.theme.spacings.md};
  }
  
  .u-margin--none {
    margin: 0;
  }
  
  .u-inline--block {
    display: inline-block;
  }
  
  .u-bold {
    font-weight: 600;
  }
  
  .c-custom-select {
    width: 50px;
  }
  .c-custom-select__control {
    border: none !important;
  }
  .c-custom-select:focus .c-custom-select__control,
  .c-custom-select__control--is-focused {
    outline: none !important;
    box-shadow: none !important;
  }
  .c-custom-select__option {
    transition: ${props => props.theme.transitions.md};
    cursor: pointer;
  }
  .c-custom-select__option--is-selected {
    background-color: ${props => props.theme.colors.main} !important;
    color: #fff !important;
  }
  .c-custom-select__option--is-focused {
    background-color: ${props => props.theme.colors.gray} !important;
    color: ${props => props.theme.colors.main} !important;
  }

  .c-custom-select__indicator-separator {
    display: none;
  }
  .c-custom-select__dropdown-indicator {
    padding: 8px 0 !important;
  }
  
  .c-icon--search {
    position: absolute;
    top: 12px;
    left:  ${props => props.isRtl ? '12px' : 'auto'};
    right:  ${props => props.isRtl ? 'auto' : '12px'};
    display: block;
    width: 26px;
    height: 26px;
    background: url(imgs/icon-search.svg) no-repeat center center;
    background-size: 26px 26px;
  }
  
  .c-icon--cat {
    position: absolute;
    top: 12px;
    left:  ${props => props.isRtl ? 'auto' : '12px'};
    right:  ${props => props.isRtl ? '12px' : 'auto'};
    display: block;
    width: 26px;
    height: 26px;
  }
  
  .c-error {
    color: ${props => props.theme.colors.error};
    font-size: ${props => props.theme.fontSizes.xs};
    margin: ${props => props.theme.spacings.xs};
  }
  
  .u-text--success {
    color: ${props => props.theme.colors.success};
  }
  
  .u-text--error {
    color: ${props => props.theme.colors.error};
  }
  
  .u-text--small {
    font-size: ${props => props.theme.fontSizes.sm};
  }
  
  .u-text--tiny {
    font-size: ${props => props.theme.fontSizes.xs};
  }
`
