import React from 'react'
import Header from './Header'
import Main from './Main'

const PageLayout = () => {

	return <div>
		<Header />
		<Main />
	</div>
}

export default PageLayout
