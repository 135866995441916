import React from 'react'
import PageWrapper from './styled/PageWrapper'
import Intro from './Intro'
import Categories from './Categories'
import BusinessList from './BusinessList'
import styled from 'styled-components'
import {LanguageContext} from './Language'

const StyledMain = styled.div`
  color: ${props => props.theme.colors.darkGray};
  background: ${props => props.theme.colors.gray};
  padding: ${props => `80px 0 ${props.theme.spacings.md}`};
  box-sizing: border-box;
  height: 100vh;
`

class Main extends React.Component {

  static contextType = LanguageContext

  constructor(props) {
    super(props)
    this.state = {
      screen: 'intro',
      // TODO - Use context
      formValues: {
        business_happiness: 75,
        business_bankrupt: 50,
        preferences: {}
      },
      data: null
    }
  }

  handleFieldChange = (name, value) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [name]: value
      },
    })
  }

  handleDataResponse = (data) => {
    this.setState({
      data
    })
  }

  changeScreen(screen) {
    this.setState({ screen })
  }

  renderContent () {

    const languageContext = this.context
    const lang = languageContext.language.id

    const formProps = {
      handleFieldChange: this.handleFieldChange,
      formValues: this.state.formValues
    }

    const dataProps = {
      handleDataResponse: this.handleDataResponse,
      data: this.state.data
    }

    switch (this.state.screen) {
      case 'intro': return <Intro onClick={() => this.changeScreen('categoreis')} lang={lang} {...formProps} />
      case 'categoreis': return <Categories onClick={() => this.changeScreen('businessList')} lang={lang} {...formProps} {...dataProps} />
      case 'businessList': return <BusinessList {...formProps} {...dataProps} />
      default:
        break
    }
  }

  render() {
    return <StyledMain>
      <PageWrapper>
        {this.renderContent	()}
      </PageWrapper>
    </StyledMain>
  }
}

export default Main
