import { apiUrl } from './config'

/*
 * Api thunks
 */
export const api = {
	getHeaders: () => ({ 'Content-Type': 'application/json' }),
	_fetch: (uri, { method, body }) => {
		const url = `${apiUrl}${uri}`
		return fetch(url, {
			method: method,
      headers: api.getHeaders(),
			body: JSON.stringify(body)
		})
	},
	get: (uri, params) => api._fetch(uri, { ...params, method: 'GET' }),
	put: (uri, params) => api._fetch(uri, { ...params, method: 'PUT' }),
	post: (uri, params) => api._fetch(uri, { ...params, method: 'POST' })
}
