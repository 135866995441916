import {Text} from '../components/Language'
import React from 'react'

export const categories = [
  {
    id: 1,
    name: 'Coffee',
    icon: '☕',
    question: <Text tid='questionCafe' />
  },
  {
    id: 2,
    name: 'Pub',
    icon: '🍺',
    question: <Text tid='questionPub' />
  },
  {
    id: 3,
    name: 'Restaurant',
    icon: '👩🏾‍🍳',
    question: <Text tid='questionRestaurant' />
  },
  {
    id: 4,
    name: 'Hairdresser',
    icon: '✂️',
    question: <Text tid='questionHairdresser' />
  },
  {
    id: 5,
    name: 'Local shop',
    icon: '🛍️',
    question: <Text tid='questionLocalShop' />
  },
  {
    id: 6,
    name: 'Other',
    icon: '❤️',
    question: <Text tid='questionOther' />
  }
]

