import { shuffle, slice } from 'lodash'

/**
 * Shuffle categories
 *
 * Display randomly 4 categories.
 * If selected "Other" it should always appear at the end.
 *
 * @param {Array} categories

 * @return Array
 */
export const shuffleCategories = (categories) => {
	const shuffled = shuffle(categories)
	const sliced = slice(shuffled, 0, 4)

	const hasOther = sliced.find(cat => cat.name === 'Other')

	if (hasOther) {
		const filtered = sliced.filter(cat => cat.name !== 'Other')
		return [...filtered, hasOther]
	}

	return sliced
}
