import React from 'react'
import Slider from 'react-slick'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import styled from "styled-components";

const Slide = styled.div`
  position:relative;
`

const ZoomIcon = styled.div`
  position: absolute;
  top: ${props => props.theme.spacings.xs}; 
  right: ${props => props.theme.spacings.xs};
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: url(imgs/icon-zoom.svg) no-repeat center center;
  background-color: rgba(79, 79, 79, .6); 
  background-size: 16px 16px;
  transition: ${props => props.theme.transitions.md};
  cursor: pointer;
  
  &:hover {
    background-color: rgba(79, 79, 79, 1); 
  }
`

class BusinessPhotos extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { photos, name } = this.props
    // TODO - Large images on desktop
    const images = photos

    const settings = {
      className: 'c-slick--photos',
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }
    return (
      <div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            imageCaption={name}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        <Slider {...settings}>
          {
            photos.map((photo, i) => <Slide key={i} className='slick-slide--single'>
              <ZoomIcon onClick={() => this.setState({ isOpen: true, photoIndex: i })} />
              <img src={photo} alt={name} />
            </Slide>)
          }
        </Slider>
      </div>
    );
  }
}


export default BusinessPhotos
