import React, {useContext} from 'react'
import { ThemeProvider } from 'styled-components'
import {theme} from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import PageLayout from './PageLayout'
import {LanguageContext} from './Language'

const Global = () => {
	const languageContext = useContext(LanguageContext)
	const isRtl = languageContext.language.id === 'he' || languageContext.language.id === 'ar'

	return <ThemeProvider theme={theme}  >
		<GlobalStyles lang={languageContext.language.id} isRtl={isRtl} />
		<PageLayout />
	</ThemeProvider>
}

export default Global
