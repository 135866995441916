import styled, { css } from 'styled-components'

const Button = styled.button`
  background: transparent;
  border-radius: ${props => props.theme.borderRadius.md};
  border: ${props => `1px solid ${props.theme.colors.main}`};
  color: ${props => props.theme.colors.main};
  padding: ${props => `${props.theme.spacings.sm} ${props.theme.spacings.md}`};
  transition: ${props => props.theme.transitions.md};
  box-sizing: border-box;
  cursor: pointer;
  
  &:focus {
    outline: none;
  }
  
  &:hover {
    border: ${props => `1px solid ${props.theme.colors.mainHover}`};
    color: ${props => props.theme.colors.mainHover};
  }
  
  &:disabled {
    opacity: .7;
    cursor: auto;
  }

  ${props =>
		props.primary &&
    css`
      background: ${props => props.theme.colors.main};
      color: #fff;
      
      &:hover {
        background: ${props => props.theme.colors.mainHover};
        border-color: ${props => props.theme.colors.mainHover};
        color: #fff;
      }
  `};
  
  ${props =>
		props.whiteMain &&
    css`
      background: #fff;
      border-color: ${props => props.theme.colors.main};
      color: ${props => props.theme.colors.main};
      
      &:hover {
        background: #fff;
        border-color: ${props => props.theme.colors.mainHover};
        color: ${props => props.theme.colors.mainHover}
      }
  `};
  
  ${props =>
		props.white &&
    css`
      background: white;
      border: ${props => `1px solid ${props.theme.colors.middleGray}`};
      color: ${props => props.hasLink ? props.theme.colors.main : props.theme.colors.darkGray};
      cursor: ${props => props.hasLink ? 'pointer' : 'auto'};
      text-decoration: none;
      
      &:hover {
        color: ${props => props.hasLink ? props.theme.colors.mainHover : props.theme.colors.darkGray};
        border: ${props => `1px solid ${props.theme.colors.middleGray}`};
      }
  `};
  
  ${props =>
		props.tiny &&
    css`
      padding:  ${props => props.theme.spacings.xs};
  `};
  
  ${props =>
		props.ghost &&
    css`
      border: none;
      color: ${props => props.theme.colors.mainHover};
      
      &:hover {
        color: ${props => props.theme.colors.main};
        border: none;
      }
  `};
  
  ${props =>
		props.isGoogleMap &&
    css`
      background: ${props => `url(imgs/icon-gmaps.png) no-repeat ${props.isRtl ? 'right' : 'left'} center`}; 
      background-size: 10px 14px;
      padding: ${props => `${props.isRtl
		? `${props.theme.spacings.xs} 16px ${props.theme.spacings.xs} 0`
		: `${props.theme.spacings.xs} 0 ${props.theme.spacings.xs} 16px`}
      `};
  `};
  
  ${props =>
		props.fixedCentered &&
    css`
      display: block;
      margin: ${props => `${props.theme.spacings.sm} auto`};
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `};
  
  ${props =>
		props.hasMargin &&
    css`
      margin: ${props => `${props.theme.spacings.md} 0`};
  `};
  
  ${props =>
		props.hasMarginSm &&
    css`
      margin: ${props => `${props.theme.spacings.sm} 0`};
  `};
    
  @media ${props => props.theme.breakpoints.md} {
    width: ${props => props.isShare ? '95%' : '100%'};
  }
`

export default Button
