const he = {
	howMuchDoYouCare: 'עד כמה העסקים הקבועים שלך תורמים לאושר שלך וחשובים לך?',
	dontCare: 'בכלל לא חשובים לי',
	hardlyCare: 'כמעט ולא חשובים לי',
	somewhatCare: 'קצת חשובים לי',
	care: 'חשובים לי',
	careALot: 'חשובים לי מאד',
	whatIsThePercentageOfBUs: 'מה לדעתך אחוז העסקים הקטנים שנאבקים כלכלית בגלל הקורונה?',
	nextBtn: 'הלאה',
	explanationText: 'כדי לעזור לעסקים הקבועים שאתם אוהבים, ספרו עליהם לחברים שלכם ותתחייבו לקנות מהם לפחות פעם אחת בחודש הקרוב.',
	startBtn: 'בואו נתחיל!',
	questionCafe: 'מה הבית קפה האהוב עליך?',
	questionPub: 'באיזה פאב כיף לך לבקר?',
	questionRestaurant: 'מסעדה טובה שאוהבים?',
	questionHairdresser: 'מי מספר אותך?',
	questionLocalShop: 'חנות מקומית אהובה?',
	questionOther: 'מי עוד חסר לך?',
	skipCat: 'לקטגוריה הבאה',
	addBtn: 'הוספה לאהובים',
	addedBtn: 'הוספנו',
	whatIsYourEmail: 'רוצה לעזור עוד?',
	description: 'נשמח לפנות אליך עם עוד דרכים בהן אפשר לעזור לעסקים האהובים עליך. מבטיחים לא להגזים.',
	sendBtn: 'זה המייל שלי',
	thanksContinue: 'לא תודה, אמשיך בלי',
	thankYou: 'אלו העסקים האהובים עלי ואני אבקר בהם עוד החודש.',
	linksDescription: '',
	shareBtn: 'להתחייבות ושיתוף!',
	validationRequired: 'שדה חובה',
	validationInvalidEmail: 'הכתובת מייל לא תקינה',
	validationEmptyBusinesses: 'צריך להוסיף לפחות עסק אחד',
	cancelBtn: 'בטל',
	copyLink: 'Copy link',
	copied: 'Copied',
	sharePost: 'אלו העסקים הקטנים האהובים עלי, וכדי לעזור להם אחרי התקופה האחרונה אני אבקר בהם עוד החודש. \n',
	sharePostQuestion: 'מי העסקים האהובים שלך? \n',
	sharePostHashtag: '#locals',
}

export default he
