export const gaEvents = {
	introScreenCareMeterClick: {
		category: 'CareMeter',
		action: 'Click',
		label: 'CareMeter.Click'
	},
	introScreenCareMeterNext: {
		category: 'CareMeter',
		action: 'Next',
		label: 'CareMeter.Next'
	},
	introScreenRiskMeterClick: {
		category: 'RiskMeter',
		action: 'Click',
		label: 'RiskMeter.Click'
	},
	introScreenRiskMeterNext: {
		category: 'RiskMeter',
		action: 'Next',
		label: 'RiskMeter.Next'
	},
	introScreenSupport: {
		category: 'Support',
		action: 'Start',
		label: 'Onboarding.Start'
	},
	businessSearch: {
		category: 'Business',
		action: 'Search field click',
		label: 'Business.Search'
	},
	businessChoose: {
		category: 'Business',
		action: 'Choose result',
		label: 'Business.Choose'
	},
	businessSkip: {
		category: 'Business',
		action: 'Skip category',
		label: 'Business.Skip'
	},
	businessAdd: {
		category: 'Business',
		action: 'Add business',
		label: 'Business.Add'
	},
	registrationInsertEmail: {
		category: 'Registration',
		action: 'Insert email',
		label: 'Registration.Input_email'
	},
	registrationSend: {
		category: 'Registration',
		action: 'Click send ',
		label: 'Registration.Send'
	},
	registrationSkip: {
		category: 'Registration',
		action: 'Skip',
		label: 'Registration.Skip'
	},
	finalScreenClickLink: {
		category: 'Final',
		action: 'Click a link',
		label: 'Final.support_bussiness'
	},
	finalScreenAppears: {
		category: 'Final',
		action: 'Screen appears',
		label: 'Final.Screen'
	},
	finalScreenClickShare: {
		category: 'Final',
		action: 'Click share',
		label: 'Final.Share_intent'
	},
	finalScreenClickShareBtn: {
		category: 'Final',
		action: 'Click one of the share buttons',
		label: 'Final.Share'
	}
}

