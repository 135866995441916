import styled, { css } from 'styled-components'

const Input = styled.input`
  background: #fff;
  border-radius: ${props => props.theme.borderRadius.md};
  border: ${props => `1px solid ${props.theme.colors.middleGray}`};
  color: ${props => props.theme.colors.darkGray};
  padding: ${props => `${props.theme.spacings.sm} ${props.theme.spacings.md}`};
  width: 50%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  transition: ${props => props.theme.transitions.md};
  cursor: pointer;
  
  &::placeholder {
    color: ${props => props.theme.colors.middleGray};
    font-size: ${props => props.theme.fontSizes.sm};
  }
  
  &:focus {
    outline: none;
  }
  
   ${props =>
    props.isSearch &&
    css`
      font-size: 22px;
      line-height: 24px;
      padding: ${props => `${props.theme.spacings.sm} ${props.theme.spacings.lg}`};
  `};
  
  @media ${props => props.theme.breakpoints.md} {
    width: 100%;
  }
`

export default Input
