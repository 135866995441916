import styled from 'styled-components'

const Suggestion = styled.div`
  background: ${props => props.isActive ? props.theme.colors.gray : '#fff'};
  border-top: ${props => props.isFirst ? `none` : `1px solid ${props.theme.colors.middleGray}`};
  color: ${props => props.theme.colors.darkGray};
  padding: ${props => `${props.theme.spacings.sm} 0`};
`

export default Suggestion
