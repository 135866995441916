import React from 'react'

const Icons = () => {
  return <p>
    <span role='img' aria-label='hot beverage'>☕️</span>
    <span role='img' aria-label='beer mug'>🍺</span>
    <span role='img' aria-label='woman cook'>👩🏾‍🍳</span>
    <span role='img' aria-label='spaghetti'>🍜</span>
    <span role='img' aria-label='nail polish'>💅🏽</span>
    <span role='img' aria-label='stuffed flatbread'>🥙</span>
    <span role='img' aria-label='hairdresser'>💇🏽‍♂</span>
  </p>
}

export default Icons
