import React from 'react'
import Button from './styled/Button'
import RangeSlider from './RangeSlider'
import Slider from 'react-slick'
import Icons from './Icons'
import { Text } from './Language'
import styled from 'styled-components'
import { Event } from './Tracking'
import { gaEvents } from '../config/gaEvents'

const IntroWrapper = styled.div`
    margin: ${props => `0 ${props.theme.spacings.md}`};
    height: calc(100vh - 100px);
`

class Intro extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			lang: props.lang,
			showSlider: true
		}
		this.next = this.next.bind(this)
		this.previous = this.previous.bind(this)
	}

	next() {
		this.slider.slickNext()
	}
	previous() {
		this.slider.slickPrev()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(prevProps.lang !== this.props.lang) {
			this.setState({ lang: this.props.lang, showSlider: false }, () =>{
				// Initial settings of slider take effect only on its first mount.
				// If we change them the new settings will not be applied.
				// We need to reinitialize slider that's why we hide and show it again to rerender.
				this.setState({showSlider: true})
			})
		}
	}

	render() {
		const { lang, showSlider } = this.state
		const { formValues, handleFieldChange, onClick } = this.props
		const isRtl = lang === 'he' || lang === 'ar'
		const { introScreenCareMeterClick, introScreenCareMeterNext,
			introScreenRiskMeterClick, introScreenRiskMeterNext, introScreenSupport } = gaEvents

		const settings = {
			dots: true,
			infinite: false,
			arrows: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			swipe: false,
			rtl: isRtl
		}

		return showSlider && <IntroWrapper>
			<Slider ref={slider => (this.slider = slider)} {...settings} >
				<div className='slick-slide--single'>
					<Icons />
					<p><Text tid='howMuchDoYouCare' /></p>
					<RangeSlider
						min={0}
						max={100}
						step={25}
						value={75}
						isRtl={isRtl}
						type='label'
						name='business_happiness'
						onChange={value => {
							// Set form value
							handleFieldChange('business_happiness', value)

							// Track GA event
							Event({ ...introScreenCareMeterClick, value })
						}}
					/>
					<Button
						onClick={() => {
							// Go to next slide
							isRtl ? this.previous() : this.next()

							// Track GA event
							Event({ ...introScreenCareMeterNext, value: formValues.business_happiness })
						}}
						primary hasMargin><Text tid='nextBtn' /></Button>
				</div>
				<div className='slick-slide--single'>
					<Icons />
					<p><Text tid='whatIsThePercentageOfBUs' /></p>
					<RangeSlider
						min={0}
						max={100}
						step={10}
						value={50}
						isRtl={isRtl}
						type='number'
						name='business_bankrupt'
						onChange={value => {
							// Set form value
							handleFieldChange('business_bankrupt', value)

							// Track GA event
							Event({ ...introScreenRiskMeterClick, value })
						}}
					/>
					<Button
						onClick={() => {
							// Go to next slide
							isRtl ? this.previous() : this.next()

							// Track GA event
							Event({ ...introScreenRiskMeterNext, value: formValues.business_bankrupt })
						}}
						primary hasMargin><Text tid='nextBtn' /></Button>
				</div>
				<div className='slick-slide--single'>
					<Icons />
					<p><Text tid='explanationText' /></p>
					<Button
						onClick={() => {
							// Track GA event
							Event(introScreenSupport)

							// Go to next screen
							onClick()
						}}
						primary><Text tid='startBtn' /></Button>
				</div>
			</Slider>
		</IntroWrapper>
	}
}


export default Intro
