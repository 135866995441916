import styled from 'styled-components'

const AutoCompleteDropdown = styled.div`
  background: ${props => `url(imgs/powered_by_google.png) #fff no-repeat
  	${props.isRtl ? 'left' : 'right'} bottom`}; 
  background-size: 140px 22px;
  border-radius: ${props => props.theme.borderRadius.md};
  border: ${props => `1px solid ${props.theme.colors.middleGray}`};
  color: ${props => props.theme.colors.darkGray};
  padding: ${props => `${props.theme.spacings.xs} ${props.theme.spacings.sm} ${props.theme.spacings.md}`};
  width: 50%;
  display: block;
  margin: 0 auto;
  box-sizing: border-box;
  transition: ${props => props.theme.transitions.md};
  cursor: pointer;
  text-align: ${props => props.isRtl ? 'right' : 'left'};
  font-size: 22px;
  line-height: 24px;
  
  @media ${props => props.theme.breakpoints.md} {
    width: 100%;
  }
`

export default AutoCompleteDropdown
