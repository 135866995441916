import React, { useContext, useEffect } from 'react'
import { languageOptions } from '../languages'
import { LanguageContext } from './Language'
import styled from 'styled-components'
import CustomSelect from './CustomSelect'
import { navigate, usePath } from 'hookrouter'

const Lang = styled.div`
  position: absolute;
  top: 10px;
  left:  ${props => props.isRtl ? '12px' : 'auto'};
  right:  ${props => props.isRtl ? 'auto' : '12px'};
  color: ${props => props.theme.colors.darkGray};
  transition: ${props => props.theme.transitions.md};
  cursor: pointer;
  
  &:hover {
    color: ${props => props.theme.colors.mainHover};
  }
`

export default function LanguageSelector() {
	const languageContext = useContext(LanguageContext)

	const handleLanguageChange = (selectedOption) => {
		const selectedLanguage = languageOptions.find(item => item.id === selectedOption.value)
		// set selected language by calling context method
		languageContext.setLanguage(selectedLanguage)
		navigate(`/${selectedOption.value !== languageOptions[0].id ? selectedOption.value : ''}`)
	}

	const path = usePath()
	const langParam = path.replace('/','')
	const valueParam = languageOptions.find(item => item.id === langParam) || languageOptions[0]
	const isRtl = valueParam.id === 'he' || valueParam.id === 'ar'

	useEffect(() => {
		handleLanguageChange({value: valueParam.id, label: valueParam.icon})
	}, [])

	return <Lang isRtl={isRtl}>
		<CustomSelect
			options={languageOptions.map(item => ({
				value: item.id, label: item.icon
			}))}
			value={{ value: valueParam.id, label: valueParam.icon }}
			onChange={handleLanguageChange}
		/>
	</Lang>
}
