import React, {useContext} from 'react'
import styled from 'styled-components'
import { languageOptions } from '../languages'
import LanguageSelector from './LanguageSelector'
import {LanguageContext} from './Language'

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  text-align: center;
  background: #fff;
  border-bottom: ${props => `1px solid ${props.theme.colors.middleGray}`};
  z-index: 9;
`

const Header = () => {
	const url = window.location.href
	const languageContext = useContext(LanguageContext)
	const logo = languageContext.language.logo
	return <StyledHeader>
		{ languageOptions.length > 1 &&  <LanguageSelector />}
		<a href={url}><img src={logo} width='100' alt='Locals logo' /></a>
	</StyledHeader>
}

export default Header
