import styled from 'styled-components'

const Panel = styled.div`
  background: #fff;
  border-radius: ${props => props.theme.borderRadius.md};
  border: ${props => `1px solid ${props.theme.colors.middleGray}`};
  color: ${props => props.theme.colors.darkGray};
  padding: ${props => `${props.theme.spacings.sm} ${props.theme.spacings.md}`};
  width: 50%;
  display: block;
  margin: ${props => `${props.theme.spacings.lg} auto`};
  box-sizing: border-box;
  transition: ${props => props.theme.transitions.md};
  
  @media ${props => props.theme.breakpoints.md} {
    width: 100%;
  }
`

export default Panel
