import styled, { css } from 'styled-components'

const SearchWrapper = styled.div`
  position: absolute;
  top: 55%;
  left: 50%;
  opacity: 0;
  width: 100%;
  padding: ${props => `${props.theme.spacings.sm} 0`};
  box-sizing: border-box;
  transform: translate(-50%, 0);
  transition: ${props => props.theme.transitions.md};
  
  ${props =>
    props.isVisible &&
    css`
      top: 150px;
      opacity: 1;
  `};
`;

export default SearchWrapper
