// Define what props.theme will look like
export const theme = {
	colors: {
		mainLight: '#def4f5',
		main: '#00d9e2',
		mainHover: '#00c4cc',
		error: '#ef593f',
		success: '#18c60d',
		gray: '#f8f8f8',
		middleGray: '#e1e1e1',
		middleDarkGray: '#979797',
		darkGray: '#4f4f4f',
	},
	fontSizes: {
		xs: '12px',
		sm: '16px',
		md: '24px',
	},
	borderRadius: {
		md: '10px',
	},
	spacings: {
		xs: '6px',
		sm: '12px',
		md: '24px',
		lg: '48px'
	},
	transitions: {
		md: 'all 300ms ease-in-out'
	},
	breakpoints: {
		sm: '(max-width: 600px)',
		md: '(max-width: 1024px)'
	}
}
