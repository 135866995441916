import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Input from './styled/Input'
import Button from './styled/Button'
import Loader from './styled/Loader'
import { sendData } from '../actions/actions'
import {LanguageContext, Text} from './Language'
import {buHappinessLabels} from '../config/buHappinessLabels'
import us from '../languages/us'
import styled from 'styled-components'
import { isEmpty } from 'lodash'
import { Event } from './Tracking'
import { gaEvents } from '../config/gaEvents'

const Styled = styled.div`
  padding-top: 70px;
   margin: ${props => `0 ${props.theme.spacings.md}`};
`

class SendEmail extends React.Component {

  static contextType = LanguageContext

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  handleSendData(data) {
		sendData(data)
			.then(response => {
				this.props.handleDataResponse(response.data)
				this.setState({ isLoading: false })
			})
			.then(() => {
				this.props.onClick()
			})
  }

  buildData(values) {

		const languageContext = this.context
		const lang = languageContext.language.id

		const buHappinessKey = buHappinessLabels[values.business_happiness].props.tid
		const preferences = values.preferences

		return {
			...values,
			language: lang,
			// Sending always the english label for now
			business_happiness: us[buHappinessKey],
			preferences: Object.keys(preferences).map(key => preferences[key])
		}
  }

  render() {
    const { registrationInsertEmail } = gaEvents

    const CustomInputComponent = (props) => <Input {...props} />
    return <Styled>
      <p>
        <span className='u-bold'><Text tid='whatIsYourEmail' /></span><br />
        <span><Text tid='description' /></span>
      </p>
      <Formik
        initialValues={{ ...this.props.formValues, email: '' }}
        validate={values => {
          const errors = {};
          if (!values.email) {
            errors.email = <Text tid='validationRequired' />
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = <Text tid='validationInvalidEmail' />
          }

          if(isEmpty(values.preferences)) {
            errors.global = <Text tid='validationEmptyBusinesses' />
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {

          // Track GA event
          const { registrationSend } = gaEvents
          Event(registrationSend)

          this.setState({ isLoading: true })

          // Send data
          this.handleSendData(this.buildData(values))

          setSubmitting(false);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Field
              as={CustomInputComponent}
              type='email'
              name='email'
              placeholder='youremail@something.com'
              onClick={() => Event(registrationInsertEmail)}
            />
            <ErrorMessage name='email' component='div' className='c-error' />
            { errors && errors.global ? <div className='c-error'>{errors.global}</div> : null }
            <div>
							<Button
								type='submit'
								disabled={isSubmitting}
								primary
								hasMarginSm
							>
								{ this.state.isLoading && <Loader small inline /> }
								<Text tid='sendBtn' />
							</Button>
						</div>

          </Form>
        )}
      </Formik>
			<div>
				<Button
					onClick={()=> {
						// Track GA event
						const { registrationSkip } = gaEvents
						Event(registrationSkip)

						// Send data without email
						this.handleSendData(this.buildData(this.props.formValues))
					}}
					whiteMain
				>
					<Text tid='thanksContinue' />
				</Button>
			</div>
    </Styled>
  }
}

export default SendEmail
