import React, {useContext} from 'react'
import Button from './styled/Button'
import {LanguageContext, Text} from './Language'
import Share from './Share'
import { categories } from '../config/categories'
import styled from 'styled-components'
import { Event } from './Tracking'
import { gaEvents } from '../config/gaEvents'

const ListsWrapper = styled.div`
    margin: ${props => `${props.theme.spacings.md} ${props.theme.spacings.md} 80px`};
`

class BusinessList extends React.Component {

	static contextType = LanguageContext

	componentDidMount() {
		// Track GA event
		const { finalScreenAppears } = gaEvents
		Event(finalScreenAppears)
	}

	renderIcon(type) {
		const category = categories.find(cat => cat.name === type)
		return category.icon
	}

	buildShareText(businesses) {

		const languageContext = this.context

		const postText = languageContext.dictionary['sharePost']
		const question = languageContext.dictionary['sharePostQuestion']
		const hashtag = languageContext.dictionary['sharePostHashtag']
		const list = businesses.map((b,i) => `- ${b.place} \n`).join(' ')

		return `
			${postText}
			${list}
			${question}
			${hashtag}
		`
	}

	render() {
		const { data } = this.props
		const { active = [], inactive = [] } = data || {}
		const { finalScreenClickLink } = gaEvents

		return <ListsWrapper>
			<p className='u-margin-bottom-sm'>
				<span className='u-bold'>❤️</span><br />
				<span className='u-bold'><Text tid='thankYou' /></span><br />
				<span><Text tid='linksDescription' /></span>
			</p>
			{
				active.length > 0 &&
          <>
            {
            	active.map(a => <Button
            		key={a.place}
            		as='a'
            		href={a.headstart_url}
            		target='_blank'
            		white
            		hasLink
            		fixedCentered
            		className='u-text-align'
            		onClick={() => Event({...finalScreenClickLink, value: a.type})}
            	>{this.renderIcon(a.type)} {a.place}</Button>)
            }
          </>
			}

			{
				inactive.length > 0 &&
        <>
          {
          	inactive.map(a => <Button
          		key={a.place}
          		white
          		fixedCentered
          		className='u-text-align'
          	>{this.renderIcon(a.type)} {a.place}</Button>)
          }
        </>
			}

			<Share shareText={this.buildShareText([...active, ...inactive])} />
		</ListsWrapper>
	}
}


export default BusinessList
