import React from 'react';
import './styles/main.css';
import Global from './components/Global'
import { LanguageProvider } from './components/Language'
import {PageView, initGA} from './components/Tracking'
import { googleAnalyticsId } from './utils/config'

class App extends React.Component {

  componentDidMount() {
    initGA(googleAnalyticsId)
    PageView()
  }

  render() {
    return (
      <div className="App">
        <LanguageProvider>
          <Global />
        </LanguageProvider>
      </div>
    )
  }
}

export default App;
