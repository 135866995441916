const us = {
	howMuchDoYouCare: 'How much do you care about the businesses you often buy from?',
	dontCare: 'I don\'t care',
	hardlyCare: 'I hardly care',
	somewhatCare: 'I Somewhat care',
	care: 'I care',
	careALot: 'I care a lot',
	whatIsThePercentageOfBUs: 'What’s the percent of local businesses you think are struggling because of corona?',
	nextBtn: 'Next',
	explanationText: 'You can help you favorite local businesses by telling your friends about them, and committing to buy from them at least once this month.',
	startBtn: 'Let\'s start',
	questionCafe: 'What is your favorite cafe?',
	questionPub: 'Where do you go for drinks?',
	questionRestaurant: 'Your go-to restaurant?',
	questionHairdresser: 'Who cuts your hair?',
	questionLocalShop: 'What is your favorite local shop?',
	questionOther: 'What other service do you miss?',
	skipCat: 'Skip Category',
	addBtn: 'Add to favorites',
	addedBtn: 'Added',
	whatIsYourEmail: 'Want to help some more?',
	description: 'We\'ll only write If there are more ways you can do to help your favorite businesses.',
	sendBtn: 'That’s my Email',
	thanksContinue: 'No Thanks, Continue',
	thankYou: 'These are my favorite local businesses and I\'ll buy from them this month.',
	linksDescription: '',
	shareBtn: 'Commit and Share!',
	validationRequired: 'Required',
	validationInvalidEmail: 'Invalid email address',
	validationEmptyBusinesses: 'Please add at least one of your favorite businesses',
	cancelBtn: 'Cancel',
	copyLink: 'Copy link',
	copied: 'Copied',
	sharePost: 'These are my favorite local businesses, and to help out I\'ll buy from them at least once this month: \n ',
	sharePostQuestion: 'Which are your favorites? \n ',
	sharePostHashtag: '#locals',
}

export default us
