import React from 'react'
import Panel from './styled/Panel'
import Button from './styled/Button'
import BusinessIcon from './styled/BusinessIcon'
import BusinessPhotos from './BusinessPhotos'
//import BusinessRating from './BusinessRating'
//import BusinessWorkingTime from './BusinessWorkingTime'
import {Text} from './Language'
import {LanguageContext} from './Language'
import { gaEvents } from '../config/gaEvents'
import { Event } from './Tracking'
//import { isEmpty } from 'lodash'

class Business extends React.Component {

  static contextType = LanguageContext

  constructor(props) {
    super(props)
    this.state = {
      isAdded: false,
      place_id: null,
      name: null,
      icon: null,
			address_components: null,
      formatted_address: null,
      permanently_closed: null,
      photos: null,
      url: null,
      website: null,
      international_phone_number: null,
    }
  }

  getPlaceDetails() {
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'))
    const request = {
      placeId: this.props.placeId,
      fields: ['name', 'icon', 'address_components', 'formatted_address', 'permanently_closed', 'photos', 'place_id', 'url',
        'formatted_phone_number', 'international_phone_number', 'website', 'price_level', 'review'
      ]
    };
    placesService.getDetails(request, (
      {place_id, name, icon, address_components, formatted_address, permanently_closed, photos, url,
        formatted_phone_number, international_phone_number, website, price_level, review},
      status) => {

      if (status === 'OK') {
        this.setState({
          isAdded: false,
          place_id,
          name,
          icon,
					address_components,
          formatted_address,
          permanently_closed,
          photos: photos && photos.length > 0 ? photos.map(photo => photo.getUrl({maxWidth: 250, maxHeight: 250})) : [],
          url,
          website,
          international_phone_number
        })
      }

    })
  }

  componentDidMount() {
   this.getPlaceDetails()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.placeId !== this.props.placeId) {
      this.getPlaceDetails()
    }
  }

  handleAddBusiness () {
    const { isAdded, name, website, international_phone_number, address_components } = this.state
    const { handleFieldChange, formValues, type, placeId, selectedCatIndex, focusSearchField } = this.props

    // Get city name
    const city = address_components.find(a => a.types.includes('locality'))
    const cityShortName = city ? city.short_name : ''

    handleFieldChange('preferences', {
      ...formValues.preferences,
      [placeId]: {
        type,
        place_id: placeId,
        place_name: name,
        website,
        phone: international_phone_number,
        city: cityShortName
      }
    })

    this.setState({ isAdded: !isAdded}, () => focusSearchField())

    // Track GA event
    const { businessAdd } = gaEvents
    Event({ ...businessAdd, value: `Screen number: ${selectedCatIndex + 1}, Category: ${type}` })
  }

  render() {
    const { isAdded, place_id, name, icon, photos, formatted_address, url } = this.state

    const languageContext = this.context
    const lang = languageContext.language.id
		const isRtl = lang === 'he' || lang === 'ar'

    return <>
      {
        place_id && <Panel>
          <div className='u-text-align'>
            <BusinessIcon src={icon} width='16' height='16' alt={`${name} icon`} />
            <p className='u-margin--none u-inline--block'>{name}</p>
            {/*TODO - Remove when is done*/}
            {/*<BusinessRating rating={rating} userRatingsTotal={user_ratings_total} />*/}
            {/*{ !isEmpty(opening_hours) && <BusinessWorkingTime openingHours={opening_hours} /> }*/}
            <p className='u-text--tiny u-margin--none'>{formatted_address}</p>
            <Button as='a' href={url} target='_blank' ghost isGoogleMap isRtl={isRtl} className='u-text--tiny'>Open in Google Maps</Button>
            { photos && photos.length > 0 ? <BusinessPhotos photos={photos} name={name} /> : null }
          </div>
          <Button
            onClick={() => {
              this.handleAddBusiness()
              setTimeout(() => this.props.handleSkipCat(), 300)
            }}
            primary={!isAdded}
            disabled={isAdded}
            hasMarginSm
          >
            { isAdded ? <Text tid='addedBtn' /> : <Text tid='addBtn' />}
          </Button>
        </Panel>
      }
    </>
  }
}


export default Business
