import styled, { css } from 'styled-components'

const PageWrapper = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  font-size: ${props => props.theme.fontSizes.md};
  line-height: 30px;
  height: 100%;
  
  ${props =>
    props.isRelative &&
    css`
      position: relative;
  `};
    
  @media ${props => props.theme.breakpoints.md} {
    width: 100%;
  }
`

export default PageWrapper
