import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: ${props => `4px solid ${props.theme.colors.middleGray}`};
  border-radius: 50%;
  border-top: ${props => `4px solid ${props.theme.colors.main}`};
  width: 16px;
  height: 16px;
  margin:  ${props => `${props.theme.spacings.xs} auto`};
  animation: ${spin} 1s linear infinite;
  
   ${props =>
    props.small &&
    css`
      width: 10px;
      height: 10px;
  `};
  
  ${props =>
    props.inline &&
    css`
      display: inline-block;
      margin: 0 6px;
  `};
`

export default Loader
