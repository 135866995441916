import React from 'react'
import Button from './styled/Button'
import {Text} from './Language'
import styled from 'styled-components'
import {
	EmailShareButton,
	EmailIcon,
	FacebookShareButton,
	FacebookIcon,
	WhatsappShareButton,
	WhatsappIcon
} from 'react-share'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Event } from './Tracking'
import { gaEvents } from '../config/gaEvents'

const StyledShare = styled.div`
  position: fixed;
  bottom: ${props => props.isVisible ? '-12px' : '-180px'};
  left: 0;
  width: 100%;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 10px #808080;
  box-sizing: border-box;
  padding: ${props => `${props.theme.spacings.md} 0`};
  transition: ${props => props.theme.transitions.md};
  z-index: 9;
  
  button {
    outline: none;
  }
`

const Title = styled.span`
    font-size: ${props => props.theme.fontSizes.sm};
  }
`

const FlexCenterAround = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`

const Label = styled.span`
    font-size: ${props => props.theme.fontSizes.xs};
    display: block;
    margin-top: ${props => props.noMargin ? '0' : '-10px'};
    color: ${props => props.theme.colors.darkGray};
`

const CopyLinkBlock = styled.div`
    width: 40px;
    height: 40px;
    display: block;
    background: ${props => `url(imgs/icon-link.svg) ${props.theme.colors.middleGray} no-repeat center center`}; 
    background-size: 22px 22px; 
    border-radius: 50%;
    margin: 0 auto;

`

const CloseButton = styled.div`
	position: absolute;
	top 2px;
	right: 6px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.main};
  padding: ${props => props.theme.spacings.xs};
  cursor: pointer;
`

class Share extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			isVisible: false,
			copied: false,
		}
	}

	toggleShareScreen() {
		this.setState({isVisible: !this.state.isVisible, copied: false})
	}

	render() {
		const { isVisible } = this.state
		const { shareText } = this.props
		const url = window.location.href

		const { finalScreenClickShareBtn } = gaEvents

		return <div>
			<StyledShare isVisible={isVisible}>
				<CloseButton onClick={() => this.toggleShareScreen()}>x</CloseButton>
				<FlexCenterAround>
					<div onClick={() => Event({ ...finalScreenClickShareBtn, value: 'Facebook' })}>
						<FacebookShareButton
							url={url}
							quote={shareText}
							className='c-icon--social'
						>
							<FacebookIcon size={40} round />
							<Label>Facebook</Label>
						</FacebookShareButton>
					</div>
					<div onClick={() => Event({ ...finalScreenClickShareBtn, value: 'WhatsApp' })}>
						<WhatsappShareButton
							url={url}
							title={shareText}
							className='c-icon--social'
						>
							<WhatsappIcon size={40} round />
							<Label>WhatsApp</Label>
						</WhatsappShareButton>
					</div>
					<div onClick={() => Event({ ...finalScreenClickShareBtn, value: 'Email' })}>
						<EmailShareButton
							url={url}
							//subject='Subject'
							body={shareText}
							className='c-icon--social'
						>
							<EmailIcon size={40} round />
							<Label>Email</Label>
						</EmailShareButton>
					</div>
					{/*TODO - Remove when approved*/}
					{/*<div onClick={() => Event({ ...finalScreenClickShareBtn, value: 'Link' })}>*/}
						{/*<CopyToClipboard*/}
							{/*text={url}*/}
							{/*onCopy={() => this.setState({copied: true})}*/}
						{/*>*/}
							{/*<CopyLinkBlock />*/}
						{/*</CopyToClipboard>*/}
						{/*<Label noMargin>*/}
							{/*<Text tid={this.state.copied ? 'copied' : 'copyLink'} />*/}
						{/*</Label>*/}
					{/*</div>*/}
				</FlexCenterAround>
			</StyledShare>
			<Button onClick={() => {
				this.toggleShareScreen()

				// Track GA event
				const { finalScreenClickShare } = gaEvents
				Event(finalScreenClickShare)
			}} primary><Text tid='shareBtn' /></Button>
		</div>
	}
}


export default Share
