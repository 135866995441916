import React from 'react'
import IconWrapper from './styled/IconWrapper'
import CategoriesWrapper from './styled/CategoriesWrapper'
import SearchWrapper from './styled/SearchWrapper'
import { categories } from '../config/categories'
import { shuffleCategories } from '../utils/helpers/shuffleCategories'
import { isNull } from 'lodash'
import Autocomplete from './Autocomplete'
import SendEmail from './SendEmail'

class Categories extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			// Remove the "Pub" option from Arabic version
			shuffledCategories: shuffleCategories(props.lang ==='ar' ? categories.filter(cat => cat.name !== 'Pub') : categories),
			selectedCatId: null,
			selectedCatIndex: null,
			skippedCats: [],
			isLastSelectedCategory: false,
			isEmailFormVisible: false,
		}
	}

	componentDidMount() {
		const index = 0

		// Select first category by index (because categories are shuffled for every user)
		// Use setTimeOut to make a transition
		setTimeout(() => {
			this.setState({
				selectedCatId: this.state.shuffledCategories[index].id,
				selectedCatIndex: index,
			})
		}, 600)

	}

	// Keep all skipped categories in order to have ability to mark them as inactive
	handleAddToSkippedCategories (skippedCats, selectedCatIndex) {
		if(!skippedCats.includes(selectedCatIndex)) {
			this.setState({
				skippedCats: [...skippedCats, selectedCatIndex]
			})
		}
	}

	// Change current selected category
	handleSkipCategory () {
		const { shuffledCategories, selectedCatIndex, skippedCats } = this.state
		const totalCategories = shuffledCategories.length
		const nextIndex = selectedCatIndex + 1

		this.setState({
			selectedCatId: shuffledCategories[nextIndex].id,
			selectedCatIndex: nextIndex,
			isLastSelectedCategory: nextIndex === totalCategories - 1
		})
		this.handleAddToSkippedCategories (skippedCats, selectedCatIndex)

	}

	// Show email form
	showEmailForm() {
		const { selectedCatIndex, skippedCats } = this.state
		this.setState({
			isEmailFormVisible: true,
			selectedCatId: 99
		})
		this.handleAddToSkippedCategories (skippedCats, selectedCatIndex)
	}

	render() {
		const { shuffledCategories, selectedCatId, selectedCatIndex, skippedCats, isLastSelectedCategory, isEmailFormVisible } = this.state
		const { handleFieldChange, formValues } = this.props
		const selectedCatObj = categories.find(cat => cat.id === selectedCatId)

		return <div>
			<CategoriesWrapper isFixed={!isNull(selectedCatId)}>
				{
					shuffledCategories.map((cat, i) => <IconWrapper
						key={cat.id}
						isActive={cat.id === selectedCatId}
						isInactive={skippedCats.includes(i)}
					>
						{cat.icon}
					</IconWrapper>)
				}
			</CategoriesWrapper>
			{
				!isEmailFormVisible && <>
          <SearchWrapper isVisible={!isNull(selectedCatId)}>
          	<p className='u-margin-bottom-sm u-padding-horizontal-md'>{selectedCatObj ? selectedCatObj.question : ''}</p>
          	<Autocomplete
          		handleSkipCategory={() => this.handleSkipCategory()}
          		category={selectedCatObj}
          		selectedCatIndex={selectedCatIndex}
          		isLastSelectedCategory={isLastSelectedCategory}
          		handleFieldChange={handleFieldChange}
          		showEmailForm={() => this.showEmailForm()}
          		formValues={formValues}
          	/>
          </SearchWrapper>

        </>
			}

			{ isEmailFormVisible && <SendEmail {...this.props} /> }
		</div>
	}
}


export default Categories
