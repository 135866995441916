import {Text} from '../components/Language'
import React from 'react'

export const buHappinessLabels = {
  0: <Text tid='dontCare' />,
  25: <Text tid='hardlyCare' />,
  50: <Text tid='somewhatCare' />,
  75: <Text tid='care' />,
  100: <Text tid='careALot' />
}
