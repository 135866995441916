import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import AutoCompleteDropdown from './styled/AutoCompleteDropdown'
import Input from './styled/Input'
import Loader from './styled/Loader'
import Suggestion from './styled/Suggestion'
import PageWrapper from './styled/PageWrapper'
import Business from './Business'
import {LanguageContext, Text} from './Language'
import Button from './styled/Button'
import { Event } from './Tracking'
import { gaEvents } from '../config/gaEvents'

class LocationSearchInput extends React.Component {

  static contextType = LanguageContext

  constructor(props) {
    super(props)
    this.state = {
      query: '',
      placeId: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.category && prevProps.category.id !== this.props.category.id) {
      this.resetQuery()
			this.focusSearchField()
    }
  }

  focusSearchField() {
		this.refs.placesAutocomplete.refs.searchInput.focus()
  }

  resetQuery () {
    this.setState({ query: '', placeId: null})
  }

  handleChange = address => {
    this.setState({ query: address })
  };

  handleSelect = address => {
    document.activeElement.blur()

    geocodeByAddress(address)
      .then(results => results[0])
      .then(res => {
        this.setState({ query: address, placeId: res.place_id})
      })
      .catch(error => console.error('Error', error))

    // Track GA event
    const { businessChoose } = gaEvents
    const { selectedCatIndex, category } = this.props
    Event({ ...businessChoose, value: `Screen number: ${selectedCatIndex + 1}, Category: ${category.name}` })
  }

  handleSkipCat() {
    const { handleSkipCategory, isLastSelectedCategory, showEmailForm } = this.props

    // Skip category
    if (!isLastSelectedCategory) {
      handleSkipCategory()
      this.resetQuery()
    }

    // Skip category as show email form when we're on last category
    if (isLastSelectedCategory) {
      showEmailForm()
    }
  }

  render() {
    const { query, placeId } = this.state
    const { selectedCatIndex, category, handleFieldChange, formValues } = this.props

    const languageContext = this.context
    const lang = languageContext.language.id
    const countryCode = languageContext.language.countryCode
		const isRtl = lang === 'he' || lang === 'ar'

    const searchOptions = {
      types: ['establishment'],
      componentRestrictions: {country: countryCode}
    }

    const { businessSearch } = gaEvents

    return (
      <>
        <PlacesAutocomplete
          value={query}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          searchOptions={searchOptions}
					ref='placesAutocomplete'
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className='u-padding-horizontal-md'>
              <PageWrapper isRelative>
                <span className='c-icon--search'></span>
                <span className='c-icon--cat'>{category ? category.icon : ''}</span>
              </PageWrapper>
              <Input type='text' onClick={() => Event({
                ...businessSearch,
                value: `Screen number: ${selectedCatIndex + 1}, Category: ${category.name}`
              })} isSearch  {...getInputProps({
                //placeholder: 'Search Places ...',
                className: 'location-search-input',
								autoFocus: true,
                ref: 'searchInput'
              })} />
              {
                suggestions.length > 0 &&
                <AutoCompleteDropdown isRtl={isRtl}>
                  {loading && <Loader />}
                  {suggestions.map((suggestion, i) => <Suggestion
                    {...getSuggestionItemProps(suggestion, {
                      className: 'u-direction'
                    })}
                    key={i}
                    isFirst={i === 0}
                    isActive={suggestion.active}
                  >
                    {suggestion.description}
                  </Suggestion>)}
                </AutoCompleteDropdown>
              }

              </div>
            )}
        </PlacesAutocomplete>

        { placeId
          ? <Business
            placeId={placeId}
            type={category.name}
            selectedCatIndex={selectedCatIndex}
            handleFieldChange={handleFieldChange}
            formValues={formValues}
            handleSkipCat={() => this.handleSkipCat()}
						focusSearchField={() => this.focusSearchField()}
          />
          : <Button onClick={() => {
            // Track GA event
            const { businessSkip } = gaEvents
            Event({ ...businessSkip, value: `Screen number: ${selectedCatIndex + 1}, Category: ${category.name}` })

            // Skip category
            this.handleSkipCat()
          }} ghost hasMargin><Text tid='skipCat' /></Button>
        }
      </>
    )
  }
}

export default LocationSearchInput
